<template>
    <v-card :height="kioskAnalyticsDimension.userSessionheight" flat>
        <v-row class="fill-height">
            <v-col cols="6" align-self="center">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === '2024'"></annual-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'This Week'"></this-week-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Today'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Yesterday'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Date'"></today-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Week'"></this-week-bar-chart>
            </v-col>
            <v-col cols="6" align-self="center" align="center">
                <pie-chart></pie-chart>
            </v-col>
            <!-- <v-col cols="3" align-self="center" align="center">
                <language-bar-chart></language-bar-chart>
            </v-col> -->
        </v-row>
    </v-card>
</template>

<script>
import AnnualBarChart from '../../../graphs/annualBarChart.vue';
import ThisWeekBarChart from '../../../graphs/thisWeekBarChart.vue';
import TodayBarChart from '../../../graphs/todayBarChart.vue';
import { mapGetters } from 'vuex'
import PieChart from '../../../graphs/pieChart.vue';
import LanguageBarChart from '../../../graphs/languageBarChart.vue';
export default {
    name: 'reports',
    props: {
        userSessionList: Array,
        selectedDuration: String,
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension'])
    },
    components: {
        AnnualBarChart,
        ThisWeekBarChart,
        TodayBarChart,
        PieChart,
        LanguageBarChart
    },
    data() {
        return {
            //
        }
    }

}
</script>
<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
}
</style>
