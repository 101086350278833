<template>
    <v-card flat class="cardBorderStyle d-flex align-center justify-center">
        <div id="chart-container"></div>
    </v-card>
</template>

<script>
import * as d3 from 'd3';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
    name: 'thisWeekBarChart',
    props: {
        userSessionList: Array,
    },
    computed: {
        ...mapGetters(['kioskAnalytics', 'kioskAnalyticsDimension', 'windowSize'])
    },
    watch: {
        kioskAnalytics(newVal) {
            console.log('at watch inside this week bar chart: ', newVal)
            this.createBarChart();
        },
        windowSize(newVal) {
            this.createBarChart();
        }
    },
    data() {
        return {
            data: [
                { category: 'Mon', value: 0 },
                { category: 'Tue', value: 0 },
                { category: 'Wed', value: 0 },
                { category: 'Thrus', value: 0 },
                { category: 'Fri', value: 0 },
                { category: 'Sat', value: 0 },
                { category: 'Sun', value: 0 }
            ],
        };
    },
    mounted() {
        //this.createBarChart();
    },
    methods: {
        createBarChart() {
            console.log('At create car chart: ', this.userSessionList)
            d3.select('#chart-container').selectAll('*').remove();
            this.data = [
                { category: 'Mon', value: 0 },
                { category: 'Tue', value: 0 },
                { category: 'Wed', value: 0 },
                { category: 'Thurs', value: 0 },
                { category: 'Fri', value: 0 },
                { category: 'Sat', value: 0 },
                { category: 'Sun', value: 0 }
            ]

            const width = this.kioskAnalyticsDimension.reportBarChartDimension.width;
            const height = this.kioskAnalyticsDimension.reportBarChartDimension.height;
            const margin = { top: 20, right: 20, bottom: 50, left: 55 };

            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const startingMonthLastYear = (currentMonth + 12 - 12) % 12;
            const categories = this.data.map(d => d.category);
            const slicedCategories = categories.slice(startingMonthLastYear + 1).concat(categories.slice(0, currentMonth + 1));
            const svg = d3.select('#chart-container')
                .append('svg')
                .attr('width', width)
                .attr('height', height);


            if (this.userSessionList.length > 0) {
                this.userSessionList.forEach(session => {
                    console.log('User Session list : ', session)
                    let day = moment(session.userSessionStartTime).format('dddd')
                    switch (day) {
                        case 'Monday':
                            var mon = this.data.find(item => item.category === 'Mon')
                            mon.value = mon.value + 1
                            break;
                        case 'Tuesday':
                            var tue = this.data.find(item => item.category === 'Tue')
                            tue.value = tue.value + 1
                            break;
                        case 'Wednesday':
                            var wed = this.data.find(item => item.category === 'Wed')
                            wed.value = wed.value + 1
                            break;
                        case 'Thursday':
                            var thrus = this.data.find(item => item.category === 'Thurs')
                            thrus.value = thrus.value + 1
                            break;
                        case 'Friday':
                            var fri = this.data.find(item => item.category === 'Fri')
                            fri.value = fri.value + 1
                            break;
                        case 'Saturday':
                            var sat = this.data.find(item => item.category === 'Sat')
                            sat.value = sat.value + 1
                            break;
                        case 'Sunday':
                            var sun = this.data.find(item => item.category === 'Sun')
                            sun.value = sun.value + 1
                            break;
                    }
                })

                const xScale = d3.scaleBand()
                    .domain(slicedCategories)
                    .range([margin.left, width - margin.right])
                    .padding(0.5);

                const yScale = d3.scaleLinear()
                    .domain([0, d3.max(this.data, d => d.value)])
                    .nice()
                    .range([height - margin.bottom, margin.top]);

                svg.selectAll('rect')
                    .data(this.data)
                    .enter()
                    .append('rect')
                    .attr('x', d => xScale(d.category))
                    .attr('y', d => yScale(d.value))
                    .attr('width', xScale.bandwidth())
                    .attr('height', d => height - margin.bottom - yScale(d.value))
                    .attr('fill', 'steelblue');

                svg.selectAll('.bar-label')
                    .data(this.data)
                    .enter()
                    .append('text')
                    .text(d => (d.value !== 0 ? d.value : ''))
                    .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
                    .attr('y', d => yScale(d.value) - 5) // Adjust the vertical position as needed
                    .attr('text-anchor', 'middle')
                    .attr('class', 'bar-label');

                svg.append('g')
                    .attr('class', 'x-axis')
                    .attr('transform', `translate(0,${height - margin.bottom})`)
                    .call(d3.axisBottom(xScale));

                svg.append('text')
                    .attr('x', width / 2)
                    .attr('y', height - margin.bottom + 40)
                    .attr('text-anchor', 'middle')
                    .style('font-size', '10px')
                    .text('WeekDays');

                svg.append('g')
                    .attr('class', 'y-axis')
                    .attr('transform', `translate(${margin.left},0)`)
                    .call(d3.axisLeft(yScale));

                svg.append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('x', -height / 2)
                    .attr('y', margin.left - 40)
                    .attr('text-anchor', 'middle')
                    .style('font-size', '10px')
                    .text('User Sessions');
            } else {
                const xScale = d3.scaleBand()
                    .domain(slicedCategories)
                    .range([margin.left, width - margin.right])
                    .padding(0.5);

                const yScale = d3.scaleLinear()
                    .domain([0, d3.max(this.data, d => d.value)])
                    .nice()
                    .range([height - margin.bottom, margin.top]);
                svg
                    .append("text")
                    .attr("x", width / 2)
                    .attr("y", height / 2)
                    .attr("text-anchor", "middle")
                    .text("No Data Available");

                svg.append('g')
                    .attr('class', 'x-axis')
                    .attr('transform', `translate(0,${height - margin.bottom})`)
                    .call(d3.axisBottom(xScale));

                svg.append('text')
                    .attr('x', width / 2)
                    .attr('y', height - margin.bottom + 40)
                    .attr('text-anchor', 'middle')
                    .style('font-size', '10px')
                    .text('WeekDays');

                svg.append('g')
                    .attr('class', 'y-axis')
                    .attr('transform', `translate(${margin.left},0)`)
                    .call(d3.axisLeft(yScale));

                svg.append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('x', -height / 2)
                    .attr('y', margin.left - 40)
                    .attr('text-anchor', 'middle')
                    .style('font-size', '10px')
                    .text('User Sessions');
            }
        },
    },
};
</script>

<style scoped>
/* Add any necessary styles for the chart */
</style>